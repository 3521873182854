import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
// import MapDetail from './SearchBar/MapDetail'
// import MapSearch from './SearchBar/MapSearch'
// import FileSearch from './SearchBar/FileSearch'
// import FileDetail from './SearchBar/FileDetail'

const MapSearch = React.lazy(() => import('./pages/MapSearch'));
const FileSearch = React.lazy(() => import('./pages/FileSearch'));
const MapDetail = React.lazy(() => import('./pages/MapDetail'));
const FileDetail = React.lazy(() => import('./pages/FileDetail'));


const styles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    border: '1px solid black',
  },
}))

const App = () => {
  const classes = styles()
  return (
    <>
      <CssBaseline />

      <div className={classes.layout}>
        <Router>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/mapsearch">
              <Route path=":mapname" element={<React.Suspense><MapSearch /></React.Suspense>} />
              <Route
                path=":mapname?/:searchName/:searchTags/:searchMessage"
                element={<React.Suspense><MapSearch /></React.Suspense>}
              />
              <Route path="" element={<React.Suspense><MapSearch /></React.Suspense>} />
            </Route>
            <Route path="/mapdetail">
              <Route path=":mapid" element={<React.Suspense><MapDetail /></React.Suspense>} />
              <Route path="" element={<React.Suspense><MapSearch /></React.Suspense>} />
            </Route>

            <Route path="/filesearch">
              <Route path=":filename" element={<React.Suspense><FileSearch /></React.Suspense>} />
              <Route
                path=":filename?/:provided/:searchMapshots/:searchSkies/:searchRequiredfiles/:searchTextures/:searchExternalfiles/:searchLinkedfiles/:searchSounds"
                element={<React.Suspense><FileSearch /></React.Suspense>}
              />
              <Route path="" element={<React.Suspense><FileSearch /></React.Suspense>} />
            </Route>
            <Route path="/filedetail">
              <Route path=":fileid" element={<React.Suspense><FileDetail /></React.Suspense>} />
              <Route path="" element={<React.Suspense><FileDetail /></React.Suspense>} />
            </Route>


            <Route path="/about" element={<About />} />
            <Route path="/users" element={<Users />} />
            <Route path="/" element={<React.Suspense><MapSearch /></React.Suspense>} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Router>
      </div>
    </>
  )
}

function About() {
  return <h2>About</h2>
}

function Users() {
  return <h2>Users</h2>
}

const Error404 = () => <h2>Requested Page not found!</h2>

export default App
